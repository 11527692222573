@import 'assets/styles/mediaQueries';

.reservation-details-wrapper {
  display: flex;
  align-items: flex-end;

  .reservation-details-section {
    flex: 1;
  }
  .reservation-details-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include large-screen {
      flex-direction: row;
    }
  }
}
