/* stylelint-disable selector-max-id, selector-max-universal, property-no-vendor-prefix */
@import './assets/styles';

html,
body,
#root {
  width: 100%;
  height: 100dvh;
  max-height: stretch;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overscroll-behavior: none;
  font-family: var(--main-font);
  color: var(--body-font-color);
  background: var(--body-background-color);
}
* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
input {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
