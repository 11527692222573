@import 'assets/styles/mediaQueries';

.reg-card-summary-form-section {
  display: flex;

  .reg-card-summary-form-section-item-large {
    flex: 5;
    margin-top: var(--spacing-xlg);
    @include large-screen {
      flex: 1;
    }
  }
  .reg-card-summary-form-section-item-small {
    flex: 3;
    margin-top: var(--spacing-xlg);
    @include large-screen {
      flex: 1;
    }
  }
}
.reg-card-disclaimer {
  margin: var(--spacing-xxlg) 0;
  text-align: justify;
}
