.external-link-header,
.external-link-footer {
  z-index: 1;
}
.external-link-body {
  position: relative;
  overflow: hidden;

  .external-link-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--body-background-color);
  }
  .external-link-iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
