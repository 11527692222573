.consent-page-wrapper {
  margin-top: var(--spacing-xlg);

  .section-component {
    border-bottom: none;
  }
  .consent-page-title {
    margin-bottom: var(--spacing-lg);
  }
  .consent-page-body {
    white-space: pre-wrap;
  }
}
