.screen-saver-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--header-background-color);

  .screen-saver-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .screen-saver-image-with-message {
    opacity: 0.7;
  }
  .screen-saver-message {
    position: absolute;
    color: var(--screen-saver-message-font-color, var(--header-font-color));
  }
}
