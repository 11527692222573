@import 'assets/styles/mediaQueries';

.registration-card-edit-complementary-wrapper {
  .registration-card-edit-complementary-fields-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs) var(--spacing-lg);
  }
  .registration-card-edit-complementary-field {
    flex: 1;
    min-width: calc(50% - var(--spacing-lg));
    @include large-screen {
      min-width: calc(33% - 2 * var(--spacing-lg));
    }
    &.checkbox-button-wrapper {
      position: relative;
      top: -2px;
      left: -10px;
      margin-top: var(--spacing-xlg);
    }
    false {
      margin-top: 0;
      @include large-screen {
        margin-top: 0;
      }
    }
  }
}
