@import 'assets/styles/mediaQueries';

.reg-card-personal-section {
  word-break: break-word;

  .reg-card-personal-section-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // stylelint-disable-next-line unit-allowed-list
    gap: 1em;
    @include large-screen {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .reg-card-personal-edit-button {
    margin: var(--spacing-xlg) 0 0 auto;
  }
}
