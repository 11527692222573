.address-grid-wrapper {
  .address-row-subgrid {
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;

    &:empty {
      display: none;
    }

    .address-details {
      grid-column: span 8;

      &:only-of-type {
        grid-column: span 12;
      }
    }
  }
}
