.finish-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--spacing-lg);
  font-size: var(--font-size-lg);
  color: var(--header-font-color);
  text-align: center;
  white-space: pre-line;
  background-color: var(--header-background-color);
}
