$marginLeft: -10px;

.terms-and-conditions-component {
  .terms-and-conditions-title {
    margin-bottom: var(--spacing-sm);
  }
  .terms-and-conditions-consents {
    margin-inline-start: $marginLeft;
    margin-top: var(--spacing-lg);

    .terms-and-conditions-link {
      text-decoration: underline;
    }
  }
}
