.preference-selection-list-wrapper {
  .preference-selection-list-list {
    padding: 0;
    margin-block: var(--spacing-sm);

    & li {
      list-style: none;
    }
  }
}
