.body-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;

  &.body-wrapper-image-position {
    &-fill {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &-fit {
      background-position: center center;
      background-size: cover;
    }
    &-stretch {
      background-size: 100% 100%;
    }
    &-tile {
      background-repeat: repeat;
    }
    &-center {
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
