.reg-card-reservation-subsection {
  flex: 1;
  margin: var(--spacing-sm) 0;
  margin-inline-end: var(--spacing-xlg);
  &:last-of-type {
    margin-inline-end: 0;
  }
  .text-component {
    margin-top: var(--spacing-sm);
  }
  .no-wrap {
    white-space: nowrap;
  }
  &-normal-font {
    font-weight: var(--font-weight-regular);
  }
  &-light-font {
    font-weight: var(--font-weight-light);
  }
  .reg-card-subsection-item {
    word-break: break-word;
  }
}
