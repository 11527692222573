.env-settings-modal-device-selection {
  display: flex;
  align-items: center;
  &:first-of-type {
    margin-top: var(--spacing-lg);
  }
  .env-settings-modal-device-field {
    flex: 1;
  }
}
